@font-face {
	font-family: "PT Sans";
	src: url("../fonts/PTSans-Regular.ttf");
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: "PT Sans";
	src: url("../fonts/PTSans-Bold.ttf");
	font-weight: 700;
	font-display: swap;
}
:root {
	--base-color-light: #f3f3f3;
	--base-color-dark: #201e29;
	--base-color-dark-opacity: rgba(32, 30, 41, 0.8);
	--base-grey-color: #f5f5f5;
	--main-color: #6285a8;
	--secondary-color: #dde6f2;
	--base-buttons-color: #0d6efd;
	--table-striped-bgcolor: #f8f9fa;
	--orange: #e5891c;
	--green: #25971b;
	--red: #dc3545;
	--pink: #e466ff;
	--status-closed: #9a9a9a;
	--ticket-primary-color: #ecf8fa;
	--ticket-secondary-color: #f0f5ff;
	--sort-bcgr: url("data:image/svg+xml,%3Csvg fill='%23000000' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px' viewBox='0 0 34.695 34.695' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M28.708,18.979c0.087,0.164,0.075,0.363-0.029,0.517L18.395,34.479c-0.09,0.132-0.237,0.211-0.397,0.217 c-0.005,0-0.01,0-0.015,0c-0.154,0-0.3-0.072-0.396-0.194L6.015,19.518c-0.117-0.15-0.137-0.354-0.053-0.525 c0.084-0.171,0.258-0.279,0.449-0.279h21.854C28.452,18.711,28.621,18.814,28.708,18.979z M6.41,15.983h21.854 c0.007,0,0.015,0,0.021,0c0.275,0,0.5-0.224,0.5-0.5c0-0.161-0.075-0.303-0.192-0.396L17.088,0.194 C16.99,0.068,16.846,0.004,16.678,0c-0.16,0.005-0.308,0.085-0.398,0.218L5.998,15.2c-0.105,0.153-0.117,0.353-0.03,0.517 C6.056,15.879,6.224,15.983,6.41,15.983z'/%3E%3C/g%3E%3C/svg%3E");
	--sort-asc-bcgr: url("data:image/svg+xml,%3Csvg width='10px' height='10px' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.272 5.205L16.272 13.205C16.8964 14.2041 16.1782 15.5 15 15.5H5.00002C3.82186 15.5 3.1036 14.2041 3.72802 13.205L8.72802 5.205C9.31552 4.265 10.6845 4.265 11.272 5.205Z' fill='%23000000'/%3E%3C/svg%3E");
	--sort-desc-bcgr: url("data:image/svg+xml,%3Csvg width='10px' height='10px' viewBox='0 0 20 20' fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.72798 15.795L3.72798 7.795C3.10356 6.79593 3.82183 5.5 4.99998 5.5L15 5.5C16.1781 5.5 16.8964 6.79593 16.272 7.795L11.272 15.795C10.6845 16.735 9.31549 16.735 8.72798 15.795Z'/%3E%3C/svg%3E");
}
input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	background: var(--base-color-light);
	color: var(--base-color-dark);
	font-family: "PT Sans", Tahoma, Verdana, Arial, sans-serif;
	font-size: 14px;
	scroll-behavior: smooth;
}
.dashboard-container {
	background: var(--base-color-light);
	color: var(--base-color-dark);
	height: auto;
	min-height: 100vh;
}
/*REMOVE INPUT TOP SHADOWS ON IOS DEVICES*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
	background-clip: padding-box;
}
.btn:not(#dropdownUser) {
	text-transform: capitalize;
}
.errorMessage {
	border: 1px solid #f5c2c7;
	border-radius: 0.25rem;
	background-color: #f8d7da;
	color: #842029;
	padding: 1rem 1rem;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
}
div.errorSummary {
	border-radius: 4px;
}
.successSummary {
	background-color: #d1e7dd;
	border: 1px solid #badbcc;
	border-radius: 4px;
	color: #0f5132;
	margin: 0 auto 1rem;
	max-width: 500px;
	padding: 1rem 1rem;
	position: relative;
}
.sidebar-heading svg {
	color: var(--base-color-dark);
	height: auto;
	width: 224px;
}
.sidebar-wrapper {
	min-height: 100vh; /* Fallback for older browsers */
	min-height: 100dvh;
	max-width: 260px;
}
.sidebar-menu {
	max-height: 100%;
	overflow-y: auto;
}
.menu-link {
	font-size: 16px;
}
.menu-link:hover {
	background-color: var(--base-buttons-color);
	color: #fff;
}
.menu-dropdown-link,
.menu-dropdown-list-link {
	color: #212529;
}
.menu-link:hover .menu-dropdown-link,
.menu-link.active .menu-dropdown-link,
.menu-link.active .menu-dropdown-toggler,
.menu-link:hover .menu-dropdown-toggler {
	color: #fff;
}
/* menu dropdowns */
.menu-dropdown-list-link:hover,
.menu-dropdown-list-link.active,
.menu-dropdown-list-link.active > .menu-dropdown-link {
	color: var(--base-buttons-color);
}
.menu-dropdown-list-link {
	align-items: center;
	display: flex;
	font-size: 16px;
	height: 40px;
	left: -100px;
	margin-top: 9px;
	opacity: 0;
	overflow: hidden;
	padding-left: 60px;
	position: relative;
	transition: opacity 0.25s ease-out, left 0.2s ease-out;
}
.menu-dropdown-sublink {
	padding-left: 78px;
}
.menu-dropdown-toggler-check:checked
	~ .menu-dropdown-list
	.menu-dropdown-list-link {
	left: 0;
	opacity: 1;
	transition: opacity 0.3s ease-in, left 0.25s ease-in;
}
.menu-dropdown-toggler-check {
	cursor: pointer;
	height: 40px;
	opacity: 0;
	right: 16px;
	top: 0;
	width: 26px;
	z-index: 10;
}
.menu-dropdown-list {
	max-height: 0;
	transition: max-height 0.15s ease-out;
	overflow: hidden;
}
.menu-dropdown-toggler-check:checked ~ .menu-dropdown-list {
	margin-bottom: 8px;
	max-height: 390px;
	transition: max-height 0.25s ease-in;
}
.menu-dropdown-toggler-check:checked ~ .menu-link,
.menu-dropdown-toggler-check:hover ~ .menu-link {
	background-color: var(--base-buttons-color);
	border-color: var(--base-buttons-color);
	color: #fff;
}
.menu-dropdown-toggler-check:checked ~ .menu-link .menu-dropdown-link,
.menu-dropdown-toggler-check:checked ~ .menu-link .menu-dropdown-toggler,
.menu-dropdown-toggler-check:hover ~ .menu-link .menu-dropdown-link,
.menu-dropdown-toggler-check:hover ~ .menu-link .menu-dropdown-toggler {
	color: #fff;
}
.menu-dropdown-toggler-check:checked ~ .menu-link .menu-dropdown-toggler {
	color: #fff;
	transform: rotate(180deg);
}
.navbar-login {
	color: #272abf;
}
.footer a {
	color: var(--base-color-dark);
	text-decoration: none;
}
.footer a:hover {
	text-decoration: underline;
}
.navbar-toggler:focus {
	box-shadow: none;
}
.login-icon {
	border: 1px solid #272abf;
	color: #272abf;
	height: 45px;
	width: 45px;
}
.menu-icon {
	width: 20px;
}
.bg-grey {
	background-color: var(--base-grey-color);
}
.bg-violet {
	background-color: #272abf;
}
.fs-12 {
	font-size: 12px;
}
.fs-16 {
	font-size: 16px;
}
.fs-18 {
	font-size: 18px;
}
.fs-20 {
	font-size: 20px;
}
.fs-24 {
	font-size: 24px;
}
.fs-40 {
	font-size: 40px;
}
.fs-44 {
	font-size: 44px;
}
.fs-56 {
	font-size: 56px;
}
.font-weight-medium {
	font-weight: 500;
}
.h-137 {
	height: 137px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-18 {
	margin-top: 18px;
}
.mt-n6 {
	margin-top: -6px;
}
.mt-n8 {
	margin-top: -8px;
}
.mt-n35 {
	margin-top: -35px;
}
.lh-21 {
	line-height: 21px;
}
.lh-31 {
	line-height: 31px;
}
.lh-52 {
	line-height: 52px;
}
.lh-68 {
	line-height: 68px;
}
.text-pink {
	color: var(--pink);
}
.color-dark-opacity {
	color: var(--base-color-dark-opacity);
}
.z-index-1 {
	z-index: 1;
}
.list-group-upper-latin {
	list-style-type: upper-latin;
}
ol ul {
	list-style-type: disc;
}
.sec-header {
	font-size: 19px;
}
.change-password-page {
	font-size: 16px;
}
.close-circle {
	height: 30px;
	width: 30px;
}
.form-label {
	color: var(--main-color);
}
.estimated-amount-label {
	line-height: 34px;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
	background-color: var(--table-striped-bgcolor);
	box-shadow: none;
}
.settings-page {
	max-width: 1180px;
}
.sp-block svg {
	height: 35px;
	width: auto;
}
.sp-block .sec-header {
	font-size: 19px;
	margin: 10px 0 22px;
}
.zero-balance-check {
	color: var(--main-color);
}
.balances-tbl {
	min-width: 700px;
}
.sweep-tbl {
	min-width: 750px;
}
.overflow-wrapper {
	transform: rotateX(180deg);
	width: 100%;
}
.overflow-x-auto {
	overflow-x: auto;
	scrollbar-color: rgba(13, 110, 253, 0.5) #f3f3f3;
	scrollbar-width: auto;
}
.overflow-x-auto:hover {
	scrollbar-color: #0d6efd rgba(212, 211, 211, 0.5);
}
.overflow-x-auto > .table {
	transform: rotateX(180deg);
}
.overflow-x-auto::-webkit-scrollbar {
	height: 15px;
}
.overflow-x-auto::-webkit-scrollbar-track {
	background-color: #f3f3f3;
	border-radius: 10px;
}
.overflow-x-auto::-webkit-scrollbar-track:hover {
	box-shadow: inset 0 0 5px rgba(212, 211, 211, 0.5);
}
.overflow-x-auto::-webkit-scrollbar-thumb {
	border-radius: 10px;
	border: 2px solid transparent;
	background-clip: content-box;
	background-color: rgba(13, 110, 253, 0.5);
}
.overflow-x-auto::-webkit-scrollbar-thumb:hover {
	background-color: #0d6efd;
}
.btn-arrange-balance {
	border: 1px solid #0d6efd;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 3px 8px;
}
.btn-arrange-balance.active,
.btn-arrange-balance:hover {
	background-color: #0d6efd;
	color: #fff;
}
.more-balances {
	bottom: 0;
	color: #9a9a9a;
	display: none;
	left: 20px;
	padding: 7px;
}
.more-wrap:hover > .more-balances {
	display: block;
}
.sweep-form {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	padding: 25px 22px 23px;
}
.sweep-form .btn-close {
	top: 22px;
	right: 22px;
}
.search-input {
	background-color: var(--secondary-color);
	border: 0;
	color: var(--main-color);
	outline: 0;
}
.search-input::placeholder {
	color: var(--main-color);
}
.search-input:focus {
	background-color: #fff;
	color: var(--main-color);
	box-shadow: none;
}
.search-btn {
	background-color: var(--secondary-color);
	color: var(--main-color);
}
.search-input:focus + .search-btn,
.search-btn:active {
	background-color: var(--main-color);
	color: #fff;
}
.search-btn:focus {
	box-shadow: none;
}
.search-btn:hover {
	color: #fff;
}
.h-234 {
	height: 234px;
}
.w-250 {
	width: 250px;
}
.w-640 {
	width: 640px;
}
.w-fit {
	width: fit-content;
}
.max-h-800 {
	max-height: 800px;
}
.min-w-200 {
	min-width: 200px;
}
.min-w-380 {
	min-width: 380px;
}
.min-w-500 {
	min-width: 500px;
}
.min-w-640 {
	min-width: 640px;
}
.max-w-60 {
	max-width: 60px;
}
.max-w-80 {
	max-width: 80px;
}
.max-w-100 {
	max-width: 100px;
}
.max-w-180 {
	max-width: 180px;
}
.max-w-224 {
	max-width: 224px;
}
.max-w-260 {
	max-width: 260px;
}
.max-w-380 {
	max-width: 380px;
}
.max-w-500 {
	max-width: 500px;
}
.max-w-520 {
	max-width: 520px;
}
.max-w-725 {
	max-width: 725px;
}
.max-w-780 {
	max-width: 780px;
}
.max-w-1022 {
	max-width: 1022px;
}
.max-w-1180 {
	max-width: 1180px;
}
.max-w-1400 {
	max-width: 1400px;
}
.max-w-1560 {
	max-width: 1560px;
}
.btn-outline-main {
	color: var(--main-color);
	border-color: var(--main-color);
}
.btn-outline-main:hover {
	color: #fff;
	background-color: var(--main-color);
	border-color: var(--main-color);
}
.btn-outline-main:hover .user-dropdown-name .text-dark {
	color: #fff !important;
}
.btn-details {
	border: 0;
	background-color: transparent;
	font-size: 0;
	width: 75px;
}
.btn-details:hover {
	background-color: var(--main-color);
	border-color: var(--main-color);
	color: #fff;
	font-size: 14px;
}
.btn-details::after {
	content: "•••";
	font-size: 16px;
}
.btn-details:hover::after {
	font-size: 0;
}
.details .copy-address {
	cursor: pointer;
	height: 15px;
}
.asset-name {
	width: 146px;
}
.asset-icon {
	height: 30px;
	width: auto;
}
.status-completed,
.table > :not(caption) .status-completed,
.table-striped > tbody > tr:nth-of-type(odd) > .status-completed,
.status-paid,
.table > :not(caption) .status-paid,
.table-striped > tbody > tr .status-paid {
	color: var(--green);
}
.status-confirming,
.table > :not(caption) .status-confirming,
.table-striped > tbody > tr:nth-of-type(odd) > .status-confirming,
.status-submitted,
.table > :not(caption) .status-submitted,
.table-striped > tbody > tr:nth-of-type(odd) > .status-submitted,
.status-pending_approval,
.table > :not(caption) .status-pending_approval,
.table-striped > tbody > tr .status-pending_approval {
	color: var(--orange);
}
.status-active,
.table > :not(caption) .status-active,
.table-striped > tbody > tr .status-active {
	color: var(--base-buttons-color);
}
.status-expired,
.table > :not(caption) .status-expired,
.table-striped > tbody > tr .status-expired,
.status-disabled,
.table > :not(caption) .status-disabled,
.table-striped > tbody > tr .status-disabled {
	color: var(--red);
}
.transactions-tbl {
	table-layout: auto;
}
.overflow-x-auto > .transactions-tbl {
	transform: none;
}
.modal-max-width {
	max-width: 520px;
}
.letter-space {
	letter-spacing: -0.32px;
}
.not-completed-block .progress {
	z-index: 0;
}
.not-completed-block .progress-bar {
	animation: processing linear 2.5s infinite;
	border-radius: 4px;
	left: 0;
	z-index: 1;
}
.not-completed-block .progress > span {
	font-size: 12px;
	z-index: 1;
}
@keyframes processing {
	from {
		left: 0;
	}
	to {
		left: 100%;
	}
}
.filter-btn {
	padding-left: 40px;
	padding-right: 20px;
}
.filter-btn svg {
	height: 10px;
	width: auto;
}
.filter-block {
	background: #ffffff;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	padding: 19px 17px;
	position: absolute;
	right: 24px;
	top: 50px;
	width: 490px;
	z-index: 2;
}
.workspaces-filter-block {
	max-width: 380px;
	right: 0;
	top: 34px;
}
.filter-block::before,
.filter-block::after {
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: "";
	display: inline-block;
	position: absolute;
}
.filter-block::before {
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 7px solid transparent;
	content: "";
	display: inline-block;
	position: absolute;
	right: 9px;
	top: -7px;
}
.filter-block::after {
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	right: 10px;
	top: -6px;
}
.page-link {
	color: var(--main-color);
}
.page-link:hover {
	color: var(--main-color);
}
.page-item.active .page-link {
	background-color: var(--main-color);
	border-color: var(--main-color);
	z-index: 1;
}
.copy-show-btn svg {
	height: 25px;
	width: auto;
}
.form-select:not(.disabled):hover,
.form-control:not(.amount-min-max):hover {
	border-color: #0d6efd;
}
.form-control:disabled:hover,
.form-control[readonly]:hover {
	border-color: #ced4da;
}
.form-select.select-caret {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
}
select option:disabled {
	color: rgba(255, 0, 0, 0.5);
}
.search-select-items {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	max-height: 191px;
	overflow-x: auto;
	padding: 8px 0;
	width: 100%;
	z-index: 1;
}
.search-select-items > div[data-name="0"] {
	display: none;
}
.search-select-selected {
	min-height: 38px;
	padding-bottom: 3px;
	padding-top: 3px;
	padding-left: 7px;
}
.search-select-selected.active {
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.search-select-items > div {
	padding: 3px 16px;
}
.same-as-selected {
	background-color: #eff5ff;
	cursor: not-allowed;
	pointer-events: none;
}
.search-select-items > div:hover {
	background-color: #e9ecef;
	cursor: pointer;
}
.search-assets {
	border-radius: 4px;
	height: calc(100% - 4px);
	left: 1px;
	padding-left: 13px;
	top: 1px;
	outline: 0;
	width: calc(100% - 35px);
}
.custom-form-select {
	background-color: var(--main-color);
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3e%3cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");
	border-color: var(--main-color);
	color: #fff;
}
.custom-form-select:hover {
	background-color: transparent;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236285a8' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3e%3cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e");
	border-color: var(--main-color);
	color: var(--main-color);
}
.custom-popup-select .select-selected .name img,
.custom-popup-select .select-ul .name img {
	height: 30px;
	margin-right: 7px;
	width: auto;
}
.custom-popup-select .select-selected .available,
.custom-popup-select .select-selected .in-usd,
.custom-popup-select .select-ul .available,
.custom-popup-select .select-ul .in-usd {
	color: #9a9a9a;
}
.custom-popup-select .select-selected .available {
	margin-left: 3rem;
	margin-right: 1rem;
}
.select-ul .row:hover {
	background-color: #e9ecef;
	cursor: default;
}
.select-selected.disabled {
	background-color: #e9ecef;
}
.select-selected > span {
	align-items: center;
	display: inline-flex;
	height: 100%;
	vertical-align: middle;
	width: auto;
}
.select-items .modal-dialog {
	max-width: 432px;
}
.select-items .modal-body {
	max-height: 268px;
}
.select-items .input-group {
	max-width: 277px;
}
.select-items .search-input:focus {
	border: 1px solid var(--main-color);
}
.select-items span.name {
	align-items: center;
	display: inline-flex;
	padding-right: 0;
}
.withdraw-page .form-label {
	font-size: 16px;
}
.amount-min-max {
	font-size: 12px;
	line-height: 17px;
	padding: 15px 12px 5px;
	position: relative;
	top: -6px;
	z-index: 0;
}
.amount-min-max > div {
	width: fit-content;
}
.fee-type {
	color: #c2c2c2;
}
.fees-btns-block .form-control {
	padding-bottom: 12px;
}
.fees-btns-block .btn-group {
	background-color: #fff;
	border-radius: 0.25rem;
	top: -6px;
}
.curr-on .form-check-input {
	background-color: #198754;
	border-color: #198754;
}
.curr-off .form-check-input {
	background-color: #dc3545;
	border-color: #dc3545;
}
.form-switch.curr-off .form-check-input:hover {
	background-position: left center;
	background-color: rgba(30, 135, 86, 0.75);
	border-color: rgba(30, 135, 86, 0.75);
	cursor: pointer;
}
.form-switch.curr-on .form-check-input[type="radio"]:hover {
	cursor: not-allowed;
}
.form-switch.curr-off .form-check-input,
.form-switch.curr-on .form-check-input {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.curr-off .form-check-input {
	background-position: left center;
}
.main-header {
	max-width: 100%;
	padding: 40px 110px 0;
}
.main-header .nav,
.main-header .user-dropdown {
	margin-top: -10px;
}
.main-header .nav-link {
	color: var(--base-color-dark);
	padding: 12px 37px;
}
.main-header .nav .nav-item:first-child {
	margin-right: 6px;
}
.main-header .nav-pills .nav-link.active,
.main-header .nav-pills .show > .nav-link {
	background-color: #272abf;
}
.close-header-notification,
.header-notifications-see-all > span {
	color: var(--main-color);
	cursor: pointer;
}
.header-notifications {
	z-index: 4;
}
.header-notifications,
.header-notifications-container {
	color: var(--main-color);
}
.header-notifications-wrapper {
	filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.2));
}
.header-notifications-container {
	max-height: calc(100vh - 150px);
	overflow: auto;
	width: 310px;
}
.header-notifications-btn svg {
	color: var(--main-color);
	height: 23px;
	width: auto;
}
.header-notifications-btn:focus {
	box-shadow: none;
	outline: 0;
}
.header-notifications-delete-all {
	color: #e6891c;
	right: 9px;
	top: 7px;
}
.notifications-triangle {
	background-color: #fff;
	display: block;
	left: 147px;
	position: relative;
	text-align: left;
	transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
	top: 6px;
	z-index: -1;
}
.notifications-triangle:before,
.notifications-triangle:after {
	background-color: inherit;
	content: "";
	position: absolute;
}
.notifications-triangle::after {
	transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
.notifications-triangle::before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
		translate(0, -50%);
}
.notifications-triangle,
.notifications-triangle::before,
.notifications-triangle::after {
	border-top-right-radius: 58%;
	height: 15px;
	width: 15px;
}
.header-notifications-title,
.header-notifications-see-all {
	padding: 5px 0;
}
.header-notification-block {
	background: #f0f5ff;
	padding: 10px;
}
.header-notification-block + .header-notification-block {
	margin-top: 3px;
}
.header-notification-block > div {
	gap: 12px;
}
.header-notification-datetime {
	color: var(--main-color);
}
.header-notifications-count {
	background-color: #e5891c;
	border-radius: 50%;
	color: #fff;
	height: 16px;
	font-size: 10px;
	line-height: 17px;
	right: -4px;
	top: -5px;
	width: 16px;
}
.second-color {
	color: #5b5ee1;
}
.home__top-container {
	padding-top: 52px;
	padding-bottom: 60px;
	padding: 52px 80px 60px;
}
.ellipse-shadow {
	bottom: -275px;
	background: rgba(14, 15, 35, 0.4);
	filter: blur(90px);
	height: 163px;
	left: 0;
	position: absolute;
	width: 499px;
	z-index: 0;
}
.license-badge {
	height: 33px;
	width: 33px;
}
.learn-more-badge {
	background-color: #fff;
	border: 1px solid #272abf;
	color: #272abf;
	font-weight: normal;
	max-width: 300px;
	min-width: 260px;
	padding-right: 8px;
	text-decoration: none;
}
.more-badge {
	height: 48px;
	width: 48px;
}
.bottom .more-badge {
	margin-right: auto;
	height: 42px;
	width: 42px;
}
.learn-more-badge > span {
	border-radius: 100px;
}
.learn-more-badge:hover > span {
	background-color: #272abf;
	color: #fff;
}
.home__top-img {
	left: -2px;
	top: 4px;
}
.home__second-container,
.home__third-container,
.home__fourth-container {
	padding: 100px 80px;
	position: relative;
}
.home__third-container .card {
	border-radius: 15px;
}
.home__third-container .card .card-body {
	border-top: 2px solid #ffffff;
}
.home-mpc {
	margin-top: 60px;
	padding: 0 80px 57px 0;
}
.home-aml,
.home-instant-conv {
	margin-top: 57px;
	padding: 0 80px 57px 0;
}
.wcu-badge {
	margin-right: 196px;
	height: 102px;
	width: 102px;
}
.home__fifth-container {
	padding: 0 80px 100px;
}
.more-options-container {
	grid-template-columns: repeat(auto-fit, minmax(auto, 435px));
	gap: 86px 164px;
	margin-top: 85px;
}
.grid {
	display: grid;
}
.case-study {
	grid-template-columns: 210px 108px 210px 121px 210px 1fr;
	grid-template-rows: auto;
}
.os__circle {
	border: 1px solid #201e29;
	box-sizing: border-box;
	height: 202px;
	width: 210px;
}
.cs__item-3 .bitcoin-wallet {
	left: -24px;
	top: 50%;
	transform: translate(0, -50%);
}
.cs__item-6 .position-absolute {
	top: -42px;
}
.our-solution {
	grid-template-columns: 210px 54px 639px 53px 210px;
	grid-template-rows: auto;
}
.os__item-6 {
	grid-column: 4 / 6;
	grid-row: 2;
}
.os__big {
	border: 1px solid #201e29;
	border-radius: 240px;
	padding: 38px 56px 46px;
	position: relative;
}
.os__arrow {
	right: 11px;
	top: 50%;
	transform: translate(0, -50%);
}
.bitcoin-wallet,
.encrypted-icon {
	background: #ffffff;
	border: 1px solid #272abf;
	box-sizing: border-box;
	height: 49px;
	position: absolute;
	width: 49px;
}
.bw__1,
.bw__2,
.bw__3 {
	position: absolute;
}
.bw__1 {
	left: -8px;
	top: 52px;
}
.bw__2 {
	left: -26px;
	top: 119px;
}
.bw__3 {
	bottom: 50px;
	left: -8px;
}
.encrypted-icon {
	height: 73px;
	width: 73px;
}
.ei__1 {
	top: -35px;
	left: 50%;
	transform: translate(-50%, 0);
}
.ei__2 {
	bottom: -35px;
	left: 50%;
	transform: translate(-50%, 0);
}
.ws-nowrap {
	white-space: nowrap;
}
.total-cont {
	grid-template-columns: repeat(3, 203px);
}
.total-cont-icon {
	height: 52px;
}
.total-block {
	height: 174px;
}
.billing-page tfoot tr {
	border-top: 1px solid #ebeced;
}
.form-control.billing-datepicker {
	background-color: #fff;
	background-image: url(../images/billing-icons/calendar.png);
	background-position: right 14px center;
	background-repeat: no-repeat;
	max-width: 280px;
	padding-right: 40px;
}
.form-control.billing-datepicker:hover {
	border-color: #0d6efd;
}
/*support*/
.support-page-create {
	background-color: rgba(125, 125, 125, 0.5);
}
.support-modal-wrap {
	max-height: calc(100vh - 90px);
	padding-left: 35px;
	padding-right: 20px;
	width: 90%;
}
.support-modal {
	overflow-y: auto;
	padding-right: 15px;
}
.ls-desc {
	letter-spacing: -0.4px;
}
[class*="ticket-status"] {
	color: var(--orange);
}
[class*="ticket-status"]:first-letter {
	text-transform: capitalize;
}
.ticket-status-completed {
	color: var(--status-closed);
}
.ticket-block-info {
	grid-template-columns: 7% 16% 34% auto;
	gap: 10px;
}
.ticket-block:hover {
	cursor: pointer;
}
.open-ticket-cont {
	column-gap: 40px;
	grid-template-columns: auto 260px;
	row-gap: 20px;
}
.card-details {
	grid-column: 2;
}
.ticket-history {
	grid-column: 1;
	grid-row: 1/3;
}
.ticket-reply-form {
	grid-row: 3;
}
.ticket-close-form {
	grid-row: 4;
}
.ticket-msg-date {
	color: var(--main-color);
}
.card-ticket-msg .card-header {
	background: none;
	border-color: rgba(98, 133, 168, 0.12);
}
.card-msg-author {
	background-color: var(--ticket-primary-color);
}
.card-msg-admin {
	background-color: var(--ticket-secondary-color);
}
.user-chat-icon {
	margin-top: -3px;
}
/* chr **************************************************/
.fld {
	background: #000;
}
.chr {
	border-collapse: collapse !important;
	display: inline-block;
	margin-right: 2px !important;
	padding: 0 !important;
	width: 6px !important;
}

.chr td {
	width: 2px !important;
	height: 4px !important;
	padding: 0 !important;
	margin: 0 !important;
	border: 0 !important;
}
.notifications-announcements {
	background-color: var(--secondary-color);
	color: var(--bs-gray-900);
}
.notification-modal-dialog {
	max-width: calc(100% - 20px);
	width: 325px;
}
.notification-modal-content {
	max-height: 95vh;
}
.notification-modal-overflow {
	max-height: 318px;
}
.notification-close-btn:focus-visible {
	outline: rgba(13, 110, 253, 0.5) solid 2px;
}
.notify-content.truncated {
	cursor: pointer;
}
.notify-content.show-less {
	background: linear-gradient(
		180deg,
		#0d6efd 0,
		#0d6efd 30%,
		rgba(240, 245, 255, 0) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.date-block-light {
	color: #adb5bd;
}
.exchange-pages-menu {
	margin-bottom: 32px;
}
.exchange-pages-menu .btn {
	padding: 8px 40px;
}
.invoice-actions .btn {
	align-items: center;
	display: inline-flex;
	height: 30px;
	padding: 0 5px;
}
.invoice-actions .btn.invoice-action-btn:not(.btn-success, .btn-danger),
.invoice-actions .invoice-link {
	color: #828282;
	height: 25px;
	justify-content: center;
	padding: 0;
	position: relative;
	width: 25px;
}
.invoice-actions .btn svg {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}
.invoice-actions .btn:hover svg {
	height: 25px;
	width: auto;
}
.invoice-create .form-control.billing-datepicker {
	max-width: 100%;
}
.invoice-create-top {
	gap: 20px;
}
.invoice-assets-container {
	width: 140px;
}
.invoice-assets-container.closed .asset-icon {
	display: none;
}
.invoice-assets-container.closed .asset-icon:nth-child(-n + 3) {
	display: inline-block;
}
.view-all-assets-btn {
	cursor: pointer;
}
.view-all-assets-btn::before {
	content: "+ ";
}
.view-all-assets-btn::after {
	color: #fff;
	content: " \25BC";
	font-size: 7px;
	position: relative;
	top: -2px;
}
.hide-all-assets-btn::before {
	content: "- ";
}
.hide-all-assets-btn::after {
	content: " \25B2";
}
/*login protection*/
.login-page {
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.login-protection-2fa {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin: 48px auto;
	max-width: 724px;
	padding: 46px 10px;
	position: relative;
	text-align: center;
	width: 100%;
}
.login-protection-title {
	color: #201e29;
	font-size: 24px;
	margin-bottom: 24px;
}
.login-2fa-close {
	align-items: center;
	background: #0d6efd;
	border-radius: 50%;
	color: #fff;
	display: inline-flex;
	height: 30px;
	justify-content: center;
	position: absolute;
	text-decoration: none;
	top: -15px;
	right: -15px;
	width: 30px;
}
.code-label-2fa {
	color: #9a9a9a;
}
.code-wrap-2fa {
	gap: 10px;
	margin: 0 auto 10px;
	width: fit-content;
}
.code-wrap-2fa input.code-input-2fa {
	background: 0 0;
	border-radius: 4px;
	border: 1px solid #9a9a9a;
	box-sizing: border-box;
	color: #0d6efd;
	font-size: 36px;
	font-weight: normal;
	height: 58px;
	padding: 5px;
	text-align: center;
	width: 44px;
}
.code-wrap-2fa input.code-input-2fa:focus {
	border: 1px solid #86b7fe;
	box-shadow: 0px 0px 0px 4px rgba(13, 110, 253, 0.25);
	border-radius: 4px;
}
.code-wrap-2fa .code-input-2fa.error {
	border-color: #dc3545;
}
.code-wrap-2fa .code-input-2fa:focus,
.code-wrap-2fa .code-input-2fa.error:focus {
	border: 2px solid #0d6efd;
	outline: none;
}
.error-msg-2fa,
.errorSummary {
	background-color: #ffb6bd;
	border-radius: 4px;
	box-shadow: none;
	box-sizing: border-box;
	color: #dc3545;
	font-size: 13px;
	margin: 25px auto 0;
	max-width: 314px;
	padding: 12px 13px 11px;
	position: relative;
	width: 100%;
}
.visuallyhidden,
input.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	-webkit-clip-path: inset(50%);
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: relative;
}
.copy-id-alert {
	left: 20px;
	top: -30px;
	width: 180px;
	z-index: 1;
}
.copy-invoice-id:hover {
	cursor: pointer;
}
.notif-settings-container {
	max-width: 100%;
	width: 1180px;
}
.no-asset-notifications-trackers {
	color: #cfd4d9;
}
.delete-asset-notification-tracker {
	color: #828282;
}
.asset-notify-amount {
	border-color: #828282;
	color: #d9d9d9;
	height: 20px;
	line-height: 20px;
	max-width: 90px;
	padding: 0 12px;
	text-align: center;
}
.general-notifications-cont .result-msg,
.assets-notifications-cont .result-msg {
	border-width: 1px;
	margin: 0;
	max-width: max-content;
	padding-top: 7px;
	padding-bottom: 8px;
	position: absolute;
	top: 0;
	left: 0;
	line-height: normal;
}
.notifications-title {
	line-height: 38px;
}
.general-notifications-cont .table-overflow {
	margin-top: 12px;
}
.general-notifications-cont .result-msg + .table-overflow {
	margin-top: 12px;
}
.assets-notifications-cont .table-overflow {
	margin-top: 12px;
}
.add-asset-block {
	width: 280px;
}
.add-asset-block .search-assets {
	height: calc(100% - 4px);
}
.asset-notify-tbl {
	min-width: 610px;
}
.note-textarea.form-control {
	min-height: auto;
	height: 38px;
}
.note-textarea::placeholder {
	color: #c2c2c2;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: -0.32px;
}
.note-textarea.form-control:focus::placeholder {
	color: transparent;
}
.modal-dialog.api-modal-dialog {
	max-width: 560px;
}
.btn.pass-state {
	border-radius: 0 0.25rem 0.25rem 0 !important;
}
th[data-sort-column] span {
	background-image: var(--sort-bcgr);
	background-size: 12px;
	background-position: center right;
	background-repeat: no-repeat;
	cursor: pointer;
	padding-right: 16px;
}
th[data-sort-column].asc span {
	background-image: var(--sort-asc-bcgr);
}
th[data-sort-column].desc span {
	background-image: var(--sort-desc-bcgr);
}
.btn-workspace {
	background: #fff;
}
.btn-workspace:hover {
	background: #f8f9fa;
}
.workspaces-list {
	max-height: 330px;
	overflow: auto;
}
.user-workspaces-wrapper {
	max-height: 250px;
}
.user-workspaces {
	padding: 5px;
}
.form-check-input:checked[type="checkbox"] + .workspace-checkbox-label {
	font-weight: bold;
}
.workspace-settings .form-select {
	width: fit-content;
}
#top_balances_result_message {
	max-width: 100%;
	width: 100%;
}
.top-balances-notify-tbl th:first-child,
.top-balances-notify-tbl td:first-child {
	width: 45%;
}
.clear-input-button {
	background: 0;
	border: 0;
	cursor: pointer;
	height: 100%;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	z-index: 1;
}
.env-container {
	background: #0d6efd;
	height: 5px;
	left: 0;
	top: 0;
	position: fixed;
	width: 100%;
	z-index: 10000000;
}
.env-badge {
	background: #0d6efd;
}
.env-container.stage,
.env-badge.stage {
	background: #e7be04;
	color: #000;
}
.env-container.local,
.env-badge.local {
	background: #7f3aae;
}
.env-badge + .header-notifications {
	margin-left: 0 !important;
}
.client-autoconvert-min {
	min-width: calc(1ch + 26px);
	width: calc(1ch + 26px);
}
.pages-navigation {
	row-gap: 15px;
}
.btn-popover-help {
	height: 20px;
	width: 20px;
}
.btn-popover-help-xs {
	height: 16px;
	width: 16px;
}
.btn-popover-help-xl {
	height: 24px;
	width: 24px;
}
.btn-popover-help svg {
	height: 100%;
	width: 100%;
}
.popover {
	border: 0;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
	margin-top: 5px !important;
}
.popover-header {
	background-color: var(--table-striped-bgcolor);
	border-bottom: 0;
}
.popover-body {
	max-height: calc(100vh - 80px);
	overflow-y: auto;
}
.popover-body img {
	height: auto;
	max-width: 100%;
}
@media (min-width: 1800px) {
	.big-ml-page {
		margin-left: 220px !important;
	}
}
@media (min-width: 1400px) {
	.footer .col-lg-2 {
		width: 12.5%;
	}
}
@media (min-width: 992px) {
	#page-content-wrapper:not(.home-page-content, .main-container) {
		width: calc(100% - 260px);
	}
	.add-asset-btn {
		padding-left: 41px;
		padding-right: 41px;
	}
	.navbar-expand-lg .offcanvas.sidebar-wrapper {
		position: sticky;
	}
}
@media (min-width: 576px) {
	.user-workspaces.row-cols-sm-2 > * {
		width: calc(50% - 0.5rem);
	}
	.modal-dialog {
		max-width: 520px;
	}
}
@media (max-width: 1300px) {
	.max-w-1022 {
		width: 100%;
	}
	.fs-40 {
		font-size: 28px;
	}
	.lh-52 {
		line-height: 40px;
	}
	.main-header {
		padding: 7px 10px 0;
	}
	.home__top-container {
		padding: 53px 10px 70px;
	}
	.home__second-container,
	.home__third-container,
	.home__fourth-container {
		padding: 70px 30px;
	}
	.max-w-sm-500 {
		max-width: 500px;
	}
	.home__fifth-container {
		padding: 0 30px 70px;
	}
}
@media (max-width: 1200px) {
	.case-study,
	.our-solution {
		grid-template-columns: auto;
	}
	.os__item-2 {
		align-items: center;
		display: inline-flex;
		height: 35px;
		margin: 0 auto 14px;
		width: auto;
	}
	.os__item-2 svg {
		height: 170px;
		transform: rotate(90deg);
		width: auto;
	}
	.os__big {
		flex-direction: column;
		height: 496px;
		padding: 30px 0 40px;
		width: 261px;
	}
	.bw__1 {
		left: 50%;
		top: -24px;
		transform: translate(-50%, 0);
	}
	.bw__2 {
		left: 25px;
		top: -5px;
	}
	.bw__3 {
		bottom: initial;
		left: initial;
		top: -5px;
		right: 25px;
	}
	.ei__1 {
		top: 50%;
		left: -20px;
		transform: translate(0, -50%);
	}
	.ei__2 {
		bottom: 50%;
		left: initial;
		transform: translate(0, 50%);
		right: -20px;
	}
	.os__arrow {
		bottom: 13px;
		right: 50%;
		top: initial;
		transform: translate(50%, 0);
	}
	.os__arrow svg {
		height: auto;
		transform: rotate(90deg);
		width: 20px;
	}
	.os__item-4 {
		margin: 8px 0;
	}
	.os__item-6 {
		grid-column: 1;
		grid-row: auto;
		margin: 40px 0 70px;
	}
	.cs__item-2 {
		margin-top: 8px;
		margin-bottom: 37px;
		transform: rotate(90deg);
	}
	.cs__item-2 svg {
		height: auto;
		width: 25px;
	}
	.cs__item-3 .bitcoin-wallet {
		left: 50%;
		top: -25px;
		transform: translate(-50%, 0);
	}
	.cs__item-4 {
		margin: 10px 0;
	}
	.cs__item-4 svg {
		height: auto;
		transform: rotate(90deg);
		width: 25px;
	}
	.cs__item-6 {
		margin: 40px 0 12px;
	}
	.cs__item-6 .position-absolute {
		top: 0;
		margin-bottom: 10px;
		position: initial !important;
	}
}
@media (max-width: 1100px) {
	.home-mpc,
	.home-aml,
	.home-instant-conv {
		border-bottom: 0 !important;
		margin-top: 50px;
		padding: 0;
	}
	.more-options-container {
		gap: 32px;
		margin-top: 50px;
	}
	.wcu-badge {
		margin-right: 60px;
	}
}
@media (max-width: 991px) {
	.main-header .nav,
	.main-header .user-dropdown {
		margin-top: 0;
	}
	.login-logo {
		left: 50%;
		transform: translate(-50%, 0px);
	}
	.max-w-sm-500 {
		max-width: 725px;
	}
}
@media (max-width: 900px) {
	.footer-links {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}
	.max-w-260 {
		max-width: 300px;
		width: 100%;
	}
}
@media (max-width: 830px) {
	.payment-gateway {
		grid-template-columns: repeat(3, calc(100% / 3));
	}
	.table-overflow {
		overflow-x: auto;
	}
	.transactions-tbl {
		min-width: 1025px;
	}
	.billing-big-table {
		min-width: 1000px;
	}
}
@media (max-width: 767px) {
	.wcu-badge {
		margin-right: 0;
	}
	.filter-block {
		left: 0;
		max-width: calc(100vw - 32px);
		right: initial;
	}
	.workspaces-filter-block {
		left: initial;
		max-width: 380px;
		right: 0;
	}
	.filter-block::before {
		left: 9px;
		right: initial;
	}
	.workspaces-filter-block::before {
		left: initial;
		right: 9px;
	}
	.filter-block::after {
		left: 10px;
		right: initial;
	}
	.workspaces-filter-block::after {
		left: initial;
		right: 10px;
	}
	.total-cont {
		grid-template-columns: repeat(3, 1fr);
	}
	.total-block {
		height: auto;
		padding: 10px;
	}
	.ticket-block-info {
		grid-template-columns: 30% auto;
	}
	.ticket-block-updated-time {
		grid-row: 1;
		grid-column: 2;
		justify-content: flex-end;
	}
	.open-ticket-cont {
		grid-template-columns: auto;
	}
	.card-details {
		grid-column: 1;
	}
	.ticket-history {
		grid-row: 2;
	}
	.workspaces-list {
		max-height: inherit;
	}
}
@media (max-width: 600px) {
	.navbar-brand img {
		height: 54px;
		width: auto;
	}
	.mt-n35 {
		margin-top: -21px;
	}
	.ellipse-shadow {
		max-width: 100%;
	}
	.exchange-pages-menu .btn {
		padding: inherit;
	}
	.top-balances-notify-tbl td:first-child {
		width: 70%;
	}
}
@media (max-width: 575px) {
	.filter-block {
		width: 300px;
	}
}
@media (max-width: 510px) {
	.fs-sm-14 {
		font-size: 14px;
	}
	.fs-sm-16 {
		font-size: 16px;
	}
	.fs-sm-25 {
		font-size: 25px;
	}
	.fs-sm-30 {
		font-size: 30px;
	}
	.header-notifications {
		order: 1;
	}
	.header-notifications-wrapper {
		left: initial !important;
		right: 0 !important;
		transform: none !important;
	}
	.notifications-triangle {
		left: 290px;
	}
}
@media (max-width: 450px) {
	.exchange-pages-menu .btn {
		padding: 5px 10px;
	}
	.custom-popup-select .select-selected .available {
		margin-left: 1rem;
	}
}
@media (max-width: 425px) {
	.min-w-380 {
		min-width: auto;
	}
}
@media (max-width: 400px) {
	.navbar-brand img {
		height: 40px;
	}
	.login-icon {
		height: 40px;
		width: 40px;
	}
	.navbar-toggler svg {
		height: 40px;
		width: 40px;
	}
	.ticket-block-info {
		grid-template-columns: auto;
	}
	.ticket-block-updated-time {
		grid-column: 1;
		grid-row: 3;
	}
	.ticket-block-replies,
	.ticket-block-id {
		justify-content: flex-end;
	}
	.user-dropdown {
		max-width: 60%;
	}
	.user-dropdown > .btn {
		width: 100%;
	}
	.user-dropdown-name {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
	.workspaces-filter-block {
		right: -5px;
	}
	.withdraw-page .form-label {
		font-size: 14px;
	}
	.estimated-amount-label {
		line-height: normal;
	}
	.withdraw-page .code-wrap-2fa {
		gap: 5px;
	}
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}
